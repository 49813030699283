.ant-drawer {
    z-index: $zindex-modal;
    color:$blue;
}

.ant-drawer-header {
    padding: $modal-header-padding;
    border-radius: 4px 4px 0 0;
    background: $blue;
    color: $body-color;
    border-bottom: 1px solid $border-color;
}

.ant-drawer-footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-top: 1px solid $border-color;
    padding: $modal-footer-padding;
    left: 0px;
    background: $blue;
}
.ant-drawer-close{
    padding-top: 10%;
    color:$white;
}
.ant-drawer-body{
    background:rgb(0,0,255);
}
.ant-drawer-mask{
    background:rgb(0,0,255);
}
.ant-drawer-content-wrapper{
    width: inherit !important;
}
